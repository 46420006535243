<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.Users.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="data">
          <h2>{{ $t("Copla.Common.Subtitles.Data") }}</h2>
          <form id="data" autocomplete="nope" v-on:submit="checkForm">
            <div class="form-group row mt-4">
              <label
                for="email"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Email") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  min="1"
                  v-model="email"
                  autocomplete="username"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="password1"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Password") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="password"
                  class="form-control"
                  name="password1"
                  id="password1"
                  min="1"
                  v-model="password1"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="password"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Password2") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="password"
                  class="form-control"
                  name="password2"
                  id="password2"
                  min="1"
                  v-model="password2"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="server"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Role") }}</label
              >
              <div class="col-lg-10">
                <select v-model="rol" class="custom-select">
                  <option
                    v-bind:value="id"
                    v-for="(value, id) in roles"
                    :key="id"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="text-center">
                  <button
                    type="submit"
                    id="save"
                    name="save"
                    class="btn btn-success mr-2"
                  >
                    {{ $t("Copla.Common.Buttons.Save") }}
                  </button>
                  <button
                    type="button"
                    id="delete"
                    name="delete"
                    class="btn btn-danger mr-2"
                    v-on:click="deleteUser"
                    v-if="this.id != -1"
                  >
                    {{ $t("Copla.Common.Buttons.Delete") }}
                  </button>
                  <button
                    type="button"
                    id="new"
                    name="new"
                    class="btn btn-primary"
                    v-on:click="cleanForm"
                    v-if="this.id != -1"
                  >
                    {{ $t("Copla.Common.Buttons.Create") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          v-if="error != ''"
          class="border rounded secciones mt-4"
          id="error"
        >
          {{ error }}
        </div>
        <table
          class="table table-sm table-striped table-dark mt-3"
          v-if="users.length > 0"
        >
          <thead>
            <tr>
              <th>{{ $t("Copla.Views.Users.Table.ID") }}</th>
              <th>{{ $t("Copla.Views.Users.Table.Email") }}</th>
              <th>{{ $t("Copla.Views.Users.Table.Role") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, i) in users"
              :key="i"
              :id="i"
              :class="activeRow == i ? 'active-row' : ''"
              v-on:click="setUser(user, i)"
            >
              <td>{{ user.id }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.rol }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toolsUsersAPI from "../js/toolsUsersAPI";
import MainMenu from "../components/MainMenu";
import ModalLoading from "../components/ModalLoading.vue";
export default {
  name: "Users",
  components: {
    MainMenu,
    ModalLoading
  },
  data() {
    return {
      id: -1,
      roles: this.$t("Copla.Common.Lists.Roles"),
      email: "",
      password1: "",
      password2: "",
      rol: "ROLE_USER",
      usersObj: {},
      activeRow: -1,
      usersAPI: new toolsUsersAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      showModalLoading: false,
      messageModal: "",
      error: "",
      currentEmail: "",
      oldEmail: ""
    };
  },
  computed: {
    users: function() {
      let roles = {
        ROLE_USER: "Usuario",
        ROLE_ADMIN: "Administrador",
        ROLE_EXTERNAL: "Externo",
        ROLE_CESyA: "CESyA",
        ROLE_AD: "Audiodescripción"
      };
      let arr = [];
      for (const id in this.usersObj) {
        let user = {
          id: id,
          email: this.usersObj[id].email,
          rol: roles[this.usersObj[id].rol[0]]
        };
        arr.push(user);
      }
      return arr;
    },
    dataSend: function() {
      return { _login: this.email, _rol: this.rol, _password: this.password1 };
    }
  },
  mounted() {
    this.loadUsers();
  },
  watch: {},
  methods: {
    ...mapActions(["logout"]),
    cleanForm: function() {
      this.id = -1;
      this.password1 = "";
      this.password2 = "";
      this.email = "";
      this.rol = "ROLE_USER";
      this.activeRow = -1;
      this.error = "";
    },
    setUser(user, i) {
      let roles = {
        Usuario: "ROLE_USER",
        Administrador: "ROLE_ADMIN",
        Externo: "ROLE_EXTERNAL",
        CESyA: "ROLE_CESyA",
        Audiodescripcion: "ROLE_AD"
      };
      this.activeRow = i;
      this.id = user.id;
      this.email = user.email;
      this.rol = roles[user.rol];
      this.oldEmail = user.email;
    },
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    deleteUser() {
      let self = this;
      this.error = "";
      if (confirm(this.$t("Copla.Common.Messages.Confirm"))) {
        this.showModal(true, this.$t("Copla.Common.Modal.Deleting"));
        this.usersAPI.deleteUser(this.id).then(response => {
          if (!response.error) {
            self.cleanForm();
            self.loadUsers();
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
      }
    },
    loadUsers() {
      this.error = "";
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      this.usersAPI.getUsers().then(response => {
        this.showModal(false);
        if (!response.error) {
          this.usersObj = response.data;
          let current = Object.values(response.data).filter(
            obj => obj.current === 1
          );
          this.currentEmail = current[0].email;
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    createUser() {
      let self = this;
      this.error = "";
      this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
      this.usersAPI.createUser(this.dataSend).then(response => {
        if (!response.error) {
          self.cleanForm();
          self.loadUsers();
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    updateUser() {
      let self = this;
      if (
        this.currentEmail == this.oldEmail &&
        this.email != this.currentEmail
      ) {
        if (confirm(this.$t("Copla.Common.Messages.ConfirmLogout"))) {
          this.error = "";
          this.showModal(true, this.$t("Copla.Common.Modal.Updating"));
          this.usersAPI.updateUser(this.dataSend, this.id).then(response => {
            if (!response.error) {
              this.showModal(false);
              this.logout();
            } else {
              this.showModal(false);
              this.error = "Error: " + response.data;
            }
          });
        }
      } else {
        this.error = "";
        this.showModal(true, this.$t("Copla.Common.Modal.Updating"));
        this.usersAPI.updateUser(this.dataSend, this.id).then(response => {
          if (!response.error) {
            this.showModal(false);
            self.loadUsers();
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
      }
    },
    checkForm(e) {
      let passwordEqual = this.password1 == this.password2;
      let someFieldBlank =
        this.email == "" || this.password1 == "" || this.password2 == "";
      let emailBlank = this.email == "";
      if (this.id == -1 && someFieldBlank) {
        alert(this.$t("Copla.Common.Errors.FieldsNotEmpty"));
      } else if (!passwordEqual) {
        alert(this.$t("Copla.Common.Errors.PasswordsEquals"));
      } else if (this.id != -1 && emailBlank) {
        alert(this.$t("Copla.Common.Errors.EmailFieldEmpty"));
      } else {
        if (this.id == -1) {
          this.createUser();
        } else {
          this.updateUser();
        }
      }
      e.preventDefault();
    }
  }
};
</script>

<style scoped>
.active-row {
  background-color: yellow !important;
  color: black;
}
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}

#error {
  font-size: 1.2em;
  color: red;
  font-weight: bold;
}
</style>
